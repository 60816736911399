import {Fragment} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image'
import logo from './logoloiValais.png'

import Card from 'react-bootstrap/Card';

import './App.css';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';


import Carousel from 'react-bootstrap/Carousel';
import BackgroundImage from './BackgroundImage';


export default function App() {

    const [showModal, setShowModal] = useState({top: false, bottom: false, left: false, right: false, more: false});

    const handleClose = () => {
        setShowModal({top: false, bottom: false, left: false, right: false, more: false});
    }

    const handleShowTopModal = () => setShowModal({top: true});
    const handleShowBottomModal = () => setShowModal({bottom: true});
    const handleShowLeftModal = () => setShowModal({left: true});
    const handleShowRightModal = () => setShowModal({right: true});
    const handleShowMoreModal = () => setShowModal({more: true});

    return (
            <Fragment>
            
            
            
                <div id="main" className="position-relative position-relative-panel align-items-center">
            
                    <BackgroundImage/>
            
            
            
            
                    <a href="#" onClick={() => handleShowTopModal()}>
                        <span className="top">
                            Investors
                        </span>
                    </a>
                    <a href="#" onClick={() => handleShowLeftModal()}>
                        <span className="left">
                            Living
                        </span>
                    </a>
                    <a href="#" onClick={() => handleShowRightModal()}>
                        <span className="right">
                            Leisure
                        </span>
                    </a>
                    <a href="#" onClick={() => handleShowBottomModal()}>
                        <span className="bottom">
                            Assets
                        </span>
                    </a>
            
                    <div className="logo" fluid >
                        <h2><b>Join the Land Of Innovation</b></h2>
                        <div>
                            <br/>
                            Are you an innovator&nbsp;? 
                            Are you an investor&nbsp;? <br/>
                            Are you providing services that bring success&nbsp;?<br/>
                            My goal is to connect all of you.
                            <br/><a style={{color: 'yellow'}} href="#" onClick={() => handleShowMoreModal()}>Overview</a>
            
                        </div>
                    </div>
            
            
            
                    <div className="fixed-bottom">
                        <Container fluid="xs">
                            <div className="split">
                                <p>Copyright {new Date().getFullYear()} The Land Of Innovation </p>
                                <p style={{float: 'right'}}>
                                    Photo credits: Pixabay and under licensing.
                                </p>
                            </div>
            
            
                        </Container>
                    </div>
            
                </div>
            
            
            
                <Modal show={showModal.top === true} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>At the top</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            At the top of the valleys, we have many exquisite villages like Verbier, Crans-Montana, Saas-Fee and Zermatt.<br/> 
                            <br/>
                            Those are four sunny Ski Resorts, for fun in winter, but also for all year
                            events and shows organized by amazing People and Touristic Teams&nbsp;!
                            <br/><br/>
                            Here you will find new assets but not only, you shall also enjoy the fresh
                            air, live in a peaceful atmosphere and do good business.
                            <br/><br/>
                            A good choice in life is to come and work with us.
                        </div>            
            
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            
                <Modal show={showModal.bottom === true} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Down in the Valley</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>There are many incredible top-level schools and research institutions
                            that are climate conscious, definitely oriented towards the future,
                            working on climate solutions, renewable energy, carbon capturing,
                            Artificial Intelligence, facial recognition IoT, biology and much more.</div>
            
            
                        <Carousel interval={4000} controls={false} indicators={false} >
                            <Carousel.Item>
                                <img alt="Hi School" src={require("./pics/assets/epfl.JPG")}/>
                                <Carousel.Caption>
                                    <div style={{backgroundColor: 'red'}}>
                                        <a href="https://www.epfl.ch/about/campus/valais-en/epfl-in-valais/" target="_blank" rel="noreferrer">EPFL in Valais</a>
                                        <br/>
                                        +41 21 695 82 00
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img alt="Hi School" src={require("./pics/assets/epfl_2.JPG")}/>
                                <Carousel.Caption>
                                    <div style={{backgroundColor: 'red'}}>
                                        <a href="https://www.epfl.ch/about/campus/valais-en/epfl-in-valais/" target="_blank" rel="noreferrer">EPFL in Valais</a>
                                        <br/>
                                        +41 21 695 82 00
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img alt="Hi School" src={require("./pics/assets/hesso.JPG")}/>
                                <Carousel.Caption>
                                    <div style={{backgroundColor: 'red'}}>
                                        <a href="https://www.hevs.ch/en/hes-so-valais-wallis/" target="_blank" rel="noreferrer">HES-SO Valais</a>
                                        <br/>
                                        +41 (0)58 606 85 11
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img alt="Hi School" src={require("./pics/assets/hesso_2.JPG")}/>
                                <Carousel.Caption>
                                    <div style={{backgroundColor: 'red'}}>
                                        <a href="https://www.hevs.ch/en/hes-so-valais-wallis/" target="_blank" rel="noreferrer">HES-SO Valais</a>
                                        <br/>
                                        +41 (0)58 606 85 11
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img alt="Hi School" src={require("./pics/assets/idiap.JPG")}/>
                                <Carousel.Caption>
                                    <div style={{backgroundColor: 'red'}}>
                                        <a href="https://www.idiap.ch/en/" target="_blank" rel="noreferrer">idiap<br/>Research Institute</a>
                                        <br/>
            
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="img-responsive" alt="Prix Créateur" src={require("./pics/assets/logoCreateurs2024_600.jpg")}/>
                                <Carousel.Caption>
                                    <div style={{backgroundColor: 'red'}}>
                                        <a href="https://www.prixcreateurbcvs.ch/fr/" target="_blank" rel="noreferrer">Highlight Valais innovations <br/>and reward promising projects</a>
                                        <br/>
            
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                        <div className="center">
                            We do not collect or send any data. We simply pass on public information that
                            can be subject to change.
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            
                <Modal show={showModal.left === true} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Stand of Living</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        The canton of Valais has a high level of quality of life, taxes aren’t high at
                        all, we have efficient authorities as well as excellent state services, rent
                        and the cost of living in general is low. The canton of Valais is known to
                        be the sunniest canton in Switzerland.
                        <br/><br/>
                        Working and living in Valais is a synonym of a better life.
                        <br/><br/>
                        You can find local biological fruit and vegetables. Located in the heart of
                        Europe, we have direct connections via public transport to Milan, Paris,
                        and many major cities in Germany.
                        The canton has its own airport, but there are also direct connections with
                        the two main Swiss hubs (Geneva and Zurich airports) that are less than
                        2 hours away.
            
                        <hr/>
            
                        <h2 className="center">
                            We have almost everything<br/>
                            The only Gem missing is 
                            <br/>
                            YOU 
                            <br/> <br/>
                        </h2>
                        <h2 className="center">
                            Join us.
                        </h2>
                        <hr/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            
                <Modal show={showModal.right === true} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Leisure and fun</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
            
                        When working hard you will need to take time to relax. This region has
                        almost everything for you to enjoy a relaxing moment.
                        Thermal baths with some amazing alpine views, but also many sports for
                        all seasons like Curling, Tennis, Marial arts, Football, Biking, Mountain
                        biking, Skiing, Windsurfing and much more.
                        There are also Fun tourist attractions, as well as all level of walks for you
                        and your whole family. You shall love living in the Canton of Valais!
            
            
                        <Carousel interval={4000} controls={false} indicators={false} >
                            <Carousel.Item>
                                <img alt="Bike School & Guide, Verbier" src={require("./pics/leasure/vtt.jpg")}/>
                                <Carousel.Caption>
                                    <div style={{backgroundColor: 'red'}}>
                                        <a href="https://www.verbierbikepark.ch/" target="_blank" rel="noreferrer">Bike School & Guide, Verbier</a>
                                        <br/>
                                        +41 27 775 33 63
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img  src={require("./pics/leasure/paragliding.jpg")}/>
                                <Carousel.Caption>
                                    <div style={{backgroundColor: 'none'}}>
                                        <a href="https://www.flybverbier.ch/" target="_blank" rel="noreferrer">FlyVerbier.ch</a>
                                        <br/>
                                        +41 27 771 68 18
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img  src={require("./pics/leasure/ski.jpg")}/>
                                <Carousel.Caption>
                                    <div style={{backgroundColor: 'red'}}>
                                        <a href="https://https://verbier4vallees.ch/en" target="_blank" rel="noreferrer">Verbier Ski Ressort</a>
                                        <br/>
                                        +41 27 775 25 11
                                    </div>
                                </Carousel.Caption>
            
                            </Carousel.Item>
            
                            <Carousel.Item>
                                <img  src={require("./pics/leasure/surf2.jpg")}/>
                                <Carousel.Caption>
                                    <a href="https://www.alaiabay.ch/" target="_blank" rel="noreferrer">AlaïA Bay</a>
                                    <br/>
                                    027 322 71 71
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img  src={require("./pics/leasure/paragliding3.jpg")}/>
                                <Carousel.Caption>
                                    <a href="https://www.verbier-summits.com/" target="_blank" rel="noreferrer">Verbier Summits</a>
                                    <br/>
                                    +41 79 710 9132
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img  src={require("./pics/leasure/curling.jpg")}/>
                                <Carousel.Caption>
                                    <a href="https://www.curlingmartigny.ch/" target="_blank" rel="noreferrer">Curling Club Martigny</a>
                                    <br/>
                                    +41 78 625 74 11
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img  src={require("./pics/leasure/realfly.jpg")}/>
                                <Carousel.Caption>
                                    <a href="https://www.realfly.ch/" target="_blank" rel="noreferrer">RealFly.ch</a>
                                    <br/>
                                    027 322 92 92
                                </Carousel.Caption>
                            </Carousel.Item>
            
                            <Carousel.Item>
                                <img  src={require("./pics/leasure/happyland.JPG")}/>
                                <Carousel.Caption>
                                    <a href="https://www.happyland.ch/" target="_blank" rel="noreferrer">Happyland.ch</a>
                                    <br/>
                                    079 155 35 75
                                </Carousel.Caption>
                            </Carousel.Item>
            
                        </Carousel>
            
                        <div className="center">
                            We do not collect or send any data. We simply pass on public information that
                            can be subject to change.
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            
                <Modal show={showModal.more === true} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>What the project is about</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        My goal is to share my network, and extend it in the Canton of Valais
                        where I recently relocated and received a warm welcome.
                        <br/><br/>
                        I do not intend to replace any existing project, in fact, my wish is to
                        extend the network, bring innovative ideas and people into the network.
                        <br/><br/>
                        The main goal is to invite innovators to create, develop and industrialize
                        his/her project in the Canton of Valais, in coordination with local partners,
                        schools, and research centers. Everyone, joining the network, can bring
                        the expertise in his/her field, for example, help to relocate, be a
                        reassuring trustworthy local person involved in the innovative project, or
                        subcontractor. Everyone joining the project must have created a trusted
                        relation with the team. This mindset can create a good atmosphere of
                        trust and invite investors to get involved in the projects.
                        <br/><br/>
                        Are you an innovator? Are you an investor? Are you providing services
                        that can make them successful? Would you like to be a key partner of
                        the network? If so, contact us
                        <br/><br/>
                        <div style={{margin: '25px', marginTop: '20px'}}>
                            <Card style={{padding: '20px', maxWidth: '400px', width: '100%', height: '200px', margin: 'auto', }} className='text-center' border='danger'>
                            <p>Projet "Land Of Innovation"</p>
                            <p>Section Valais</p>
                            <p>Case postale 185</p>
                            <p>1920 Martigny</p>
                            </Card>
                        </div>                    
            
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            
            
            </Fragment>
            );
}

