import React, { useRef, useEffect, useState } from 'react'

import aletsch from './pics/background/aletsch.jpg';
import verbier from './pics/background/verbier.JPG';
import martigny from './pics/background/martigny.JPG';
import cervin from './pics/background/cervin.jpg';
import monteRosa from './pics/background/monte-rosa.jpg';
import lac from './pics/background/lac.jpg';

export default function BackgroundImage() {

    const [lastchange, setLastchange] = useState(0);
    const [random, setRandom] = useState(0);

    const ids = [martigny, verbier, cervin, aletsch, monteRosa, lac];

    const len = ids.length;


    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            let id = setInterval(() => {
                savedCallback.current();
            }, delay);
            return () => clearInterval(id);
        }, [delay]);
    }


    useInterval(() => {

        setRandom(Math.floor(Math.random() * len));

    }, 12000);

   
    return (
            <div style={{
                            backgroundImage: `url(${ids[random]}`,
                            backgroundSize: 'cover',
                            height: '90vh'
                        }} />
            );

}